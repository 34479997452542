<template>
  <div class="navbar-container d-flex content align-items-center on_move">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none on_nomove">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <feather-icon v-show="electron_show" style="cursor: pointer" icon="Minimize2Icon" size="21" class="on_nomove" @click="set_min"/>
      <feather-icon v-show="electron_show" style="cursor: pointer" icon="MaximizeIcon" size="21" class="on_nomove ml-1"  @click="set_max"/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto on_nomove">
      
      
      <dark-Toggler class=" on_nomove d-lg-block" />
      <!-- <span style="margin-left: 5px;cursor: pointer"><feather-icon icon="RepeatIcon" size="20"  @click="on_reload"/></span> -->
      <notification-dropdown />
      
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{admin_name}}
            </p>
            <span class="user-status">{{admin_rolename}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="admin_avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="go_index()">
          <feather-icon
            size="16"
            icon="CastIcon"
            class="mr-50"
          />
          <span>驾驶舱</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center"   @click="mod_pwd()">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>修改密码</span>
        </b-dropdown-item>
        
<!-- 
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>邮件往来</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>日程待办</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>在线聊天</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center"  @click="loginout()">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
          <span>注销账号</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BForm,BFormGroup, BFormInput,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import NotificationDropdown from './components/NotificationDropdown.vue'
import isElectron from 'is-electron'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
    NotificationDropdown,
    BForm,
    BFormGroup, 
    BFormInput,
  },
  data(){
    return {
      admin_name:"管理员",
      admin_avatar:"",
      electron:0,
      electron_show:false
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    this.admin_name = userData.fullName
    this.admin_rolename = userData.role
    this.admin_avatar = userData.avatar
    if (isElectron()) {
      this.electron = 1
      this.electron_show = true
    }
    // console.log('导航');
  },
  methods:{
    on_reload(){
      location.reload()
    },
    go_index(){
      this.$router.replace("/")
    },
    mod_pwd(){
       // with html
      this.$swal({
        title: '修改密码',
        // icon: 'info',
        html:'<span>新密码</span>&nbsp;&nbsp;<input type="text" id="new_pwd" style="padding: 0.5rem 1rem;border-radius: 5px;background:transparent;border:1px solid #ccc;">',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
            let new_pwd = document.getElementById("new_pwd").value
            this.mod_pwd2(new_pwd)
        }
      })
    },
    mod_pwd2(new_pwd){
      if(!new_pwd){
        return;
      }
      let dataObj = {
        act: "mod_pwd",
        password: new_pwd
      }
      this.$http.post('svr/admin_users.php', dataObj)
      .then(res => {
        this.showToast('success', '密码修改成功')
      })
    },
    loginout(){
      this.$swal({
        title: '系统提示',
        text: "确定退出系统注销账号吗?",
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '我确定!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.loginout()
        }
      })
    },
    set_min(){
      if (this.electron==1) {
        const {ipcRenderer} = window.require('electron');
        ipcRenderer.send('window-min');
      }
      
    },
    set_max(){
      if (this.electron==1) {
        const {ipcRenderer} = window.require('electron');
        ipcRenderer.send('window-max');
      }
      
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '系统提示',
          icon: 'BellIcon',
          text: text,
          variant,
        },
      })
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.on_move{
  -webkit-app-region: drag;
}
.on_nomove{
 -webkit-app-region: no-drag
}
</style>
