<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notice_nums"
        badge-classes="bg-warning"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          通知中心
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{notice_nums}} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notice in notice_list"
        :key="notice.id"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notice.data.img"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              <feather-icon
            :icon="notice.icons"
            size="12"
          />{{ notice.adm_id+notice.data.cz_title+notice.data.sub_title+":"+notice.data.title }}
            </span>
          </p>
          <small class="notification-text">{{ notice.datetime }}</small>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          系统通知
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>

      <!-- System Notifications -->
      <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="go_url('/system-logs')"
    >查看所有通知日志</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
 

    const systemNotifications = [
      {
        title: '系统登入记录',
        subtitle: '近期有5个不同ip登入系统',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: '系统访问记录',
        subtitle: '无异常访问记录',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      // Filter
      notice_nums: 0, 
      notice_list:[]
    }
  },
  watch: {
    // notice_nums: function (oldV, newV) {
    //   this.get_data()
    // },
  },
  mounted() {
    // this.get_data()
  },
  methods:{
    go_url(path){
      this.$router.replace(path)
    },
    get_data() {
      let dataObj = {
        act: "",
      }
      this.$http.post('svr/notice.php', dataObj)
        .then(res => {
          //console.log(res,'通知');
          this.notice_nums = res.data.notice_nums
          this.$nextTick(() => {
            this.notice_list = res.data.lst
          })

          
        })
    },
  }
}
</script>

<style>

</style>
