export default [
  {
    title: '主控面板',
    route: 'home',
    icon: 'HomeIcon',
    action: 'home',
  },
  {
    title: '路灯模块',
    icon: 'FigmaIcon',
    children: [
      
      {
        title: '路灯管理',
        route: 'lamp_device',
        icon: 'CommandIcon',
        action: 'lamp_device',
      },
      {
        title: '网关管理',
        route: 'lamp_gateway',
        icon: 'CodepenIcon',
        action: 'lamp_gateway',
      },
      {
        title: '定时管理',
        route: 'regular_time',
        icon: 'WatchIcon',
        action: 'regular_time',
      },
    ],
  },
  {
    title: '广播模块',
    icon: 'MicIcon',
    children: [
      {
        title: '广播管理',
        route: 'boardcast_device',
        icon: 'ServerIcon',
        action: 'boardcast_device',
      },
      {
        title: '媒体管理',
        route: 'boardcast_voice',
        icon: 'ShoppingBagIcon',
        action: 'boardcast_voice',
      },
      {
        title: '计划管理',
        route: 'boardcast_plan',
        icon: 'GitPullRequestIcon',
        action: 'boardcast_plan',
      },
    ],
  },
  {
    title: '文章模块',
    icon: 'CoffeeIcon',
    children: [
      {
        title: '栏目管理',
        route: 'news-menu',
        icon: 'CopyIcon',
        action: 'news-menu',
      },
      {
        title: '文章管理',
        route: 'news-manage',
        icon: 'MapIcon',
        action: 'news-manage',
      },
    ],
  },
  {
    title: '系统设置',
    icon: 'CpuIcon',
    children: [
      {
        title: '用户管理',
        route: 'system-admin',
        icon: 'UserCheckIcon',
        action: 'system-admin',
      },
      {
        title: '菜单管理',
        route: 'system-menu',
        icon: 'GitPullRequestIcon',
        action: 'system-menu',
      },
      {
        title: '角色管理',
        route: 'system-role',
        icon: 'MehIcon',
        // acl: {
          action: 'system-role',
          resource: 'ACL',
        //},
      },
      {
        title: '系统日志',
        route: 'system-logs',
        icon: 'LayersIcon',
        action: 'system-logs',
        resource: 'add',
      }
    ],
  },
]
